<template>
  <div>
    <el-container>
      <el-header>
        <main-menu-bar></main-menu-bar>
      </el-header>
      <el-container>
        <el-main>
          <keep-alive>
            <router-view/>
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import {baseTitle} from '../configs/squareConfig'
import MainMenuBar from "../components/MainMenuBar";

export default {
  name: "Home",
  components: {MainMenuBar},
  activated() {
    document.title = '管理首页 - ' + baseTitle
  }
}
</script>

<style scoped>

</style>
