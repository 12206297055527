<template>
  <div :style="menuBarStyle">
    <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
      <el-menu-item index="welcome">
        <i class="el-icon-s-home"></i> 管理首页
      </el-menu-item>
      <el-submenu index="raw">
        <template slot="title"><i class="el-icon-s-grid"></i> 原辅料管理</template>
        <el-menu-item index="epi-substrate">衬底管理</el-menu-item>
        <el-menu-item index="raw-info">原辅料基础信息管理</el-menu-item>
        <el-menu-item index="raw-sku">原辅料库存管理</el-menu-item>
        <el-menu-item index="raw-up-logs">原辅料入库日志</el-menu-item>
        <el-menu-item index="raw-ex-logs">原辅料出库日志</el-menu-item>
      </el-submenu>
      <el-submenu index="prod">
        <template slot="title"><i class="el-icon-cpu"></i> 成品管理</template>
        <el-submenu index="prod-square">
          <template slot="title">方片管理</template>
          <el-menu-item index="prod-square-plugin">方片插件库</el-menu-item>
          <el-menu-item index="prod-square-sku">方片库存管理</el-menu-item>
          <el-menu-item index="prod-square-sku-logs">方片操作日志</el-menu-item>
          <el-menu-item index="prod-square-sku-pv">方片库存透视</el-menu-item>
          <el-menu-item index="prod-square-sku-pv-out">方片透视数据导出</el-menu-item>
        </el-submenu>
        <el-submenu index="prod-round">
          <template slot="title">圆片管理</template>
          <el-menu-item index="prod-round-sku">圆片库存管理</el-menu-item>
          <el-menu-item index="prod-round-sku-logs">圆片操作日志</el-menu-item>
        </el-submenu>
        <el-submenu index="prod-extension">
          <template slot="title">外延片管理</template>
          <el-menu-item index="prod-extension-sku">外延片库存管理</el-menu-item>
          <el-menu-item index="prod-extension-in">外延入库日志</el-menu-item>
          <el-menu-item index="prod-extension-out">外延出库日志</el-menu-item>
          <el-menu-item index="prod-extension-in-t">外延今日入库</el-menu-item>
          <el-menu-item index="prod-extension-out-t">外延今日出库</el-menu-item>
          <el-menu-item index="prod-extension-make">外延生产日志</el-menu-item>
          <el-menu-item index="prod-extension-make-t">外延今日生产</el-menu-item>
          <el-menu-item index="prod-extension-is-in">外延在库统计</el-menu-item>
        </el-submenu>
      </el-submenu>
      <el-submenu index="other">
        <template slot="title"><i class="el-icon-setting"></i> 其它信息管理</template>
        <el-menu-item index="other-users">账户管理</el-menu-item>
        <el-menu-item index="other-custom">客户信息管理</el-menu-item>
        <el-menu-item index="other-source">来源厂家管理</el-menu-item>
      </el-submenu>
    </el-menu>
    <div class="line"></div>
  </div>
</template>

<script>
import {formRequest} from "../http/formRequest";
import {baseUrl} from "../configs/squareConfig";

export default {
  name: "MainMenuBar",
  data() {
    return {
      activeIndex: 'welcome',
      menuBarStyle: 'width:100%;float:left',
    }
  },
  methods: {
    handleSelect(key) {
      let toUrl = '/monitor/home/' + key
      const url = this.$route.path
      if (url !== toUrl) this.$router.push(toUrl)
      this.setPath(key)
    },
    setPath(path) {
      if (!path) path = window.location.href
      let arr = path.split('/')
      let toPath = arr[arr.length - 1]
      this.activeIndex = toPath
    },
    OutputSPv() {
      formRequest(baseUrl + '/square-pv-output', {
        token: this.$cookies.get('token')
      })
    },
  },
  activated() {
    this.setPath()
    const w = window.screen.width - 100
    this.menuBarStyle = 'width:' + w + 'px;float:left'
  },
}
</script>

<style scoped>

</style>
